body {

    padding: 0px;
    margin: 0px;
    font-family: 'Helvetica'; 
}

*{
    font-family: 'Helvetica'; 
}

@font-face {
    font-family: 'Helvetica';
    src: local('Helvetica'), url('../fonts/Helvetica/Helvetica.ttf') format('truetype')
}

/* Light font has a problem */
/* @font-face {
    font-family: 'HelveticaLight';
    src: local('HelveticaLight'), url('../fonts/Helvetica/Helvetica-Light.ttf') format('truetype')
} */

@font-face {
    font-family: 'HelveticaOblique';
    src: local('HelveticaOblique'), url('../fonts/Helvetica/Helvetica-Oblique.ttf') format('truetype')
}

@font-face {
    font-family: 'HelveticaBold';
    src: local('HelveticaBold'), url('../fonts/Helvetica/Helvetica-Bold.ttf') format('truetype')
}

@font-face {
    font-family: 'HelveticaBoldOblique';
    src: local('HelveticaBoldOblique'), url('../fonts/Helvetica/Helvetica-BoldOblique.ttf') format('truetype')
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* a , button {
    font-size: 16px;
} */


/* Container */

/* Header */
/* header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0px 24px;
    margin-bottom:24px;
} */

/* header nav {
    display: block;
}

header nav a {
    margin: 0px 20px;
    padding: 4px;
    color: var(--black);
    text-decoration: none;
}

header nav a.active {
    border-bottom: 2px solid var(--aquablue);
}

.auth button {
    padding: 12px 30px;
    margin: 0px 12px;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
}

button.blue-outlined {
    border: 2px solid var(--blue);
    color: var(--blue);
    font-weight: bold;
    background-color: var(--white);
}

button.blue {
    background-color: var(--blue);
    color: var(--white); 
} */

/* Footer */
/* footer {
    height: 294px;
    background-color: var(--blue);
    color: var(--white);
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding-top: 50px;
    box-sizing: border-box;
    
}

footer section {
    min-width: 300px;
}

footer h3 {
    color: var(--white);
    margin: 0px 0px 26px;
}

footer nav a, footer .working-hours div {
    margin: 8px 0px;
    padding: 4px 0px;
}

footer nav a {
    display: block;
    color: var(--white);
    text-decoration: none;
}

footer nav .working-hours {
    display: block;
}
footer nav .working-hours div {
    display: flex;
    justify-content: space-between;
} */

/* Home */
/* .home .slider {
    background-image: url('../svg/home-slider-bg.svg');
    background-repeat: no-repeat;
    background-size: 1290px 500px;
    background-position: right;
    height: 500px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.home .slider h1 {
    font-size: 36px;
    color: var(--blue);
}

.home .partners {
    height: 430px;
    text-align: center;
} */

/* Variables */
/* :root {
    --black: black;
    --blue: #193A8D;
    --aquablue: #326FE6;
    --white: #ffffff;
  } */